import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
// import { PDFDownloadLink } from "@react-pdf/renderer";
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import DownloadUserKYCDetails from '../components/export/KYC';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { get_image_url } from '../utils';

const GET_USER_KYC_DETAILS = gql`
  query getUserKYCDEtails($id: Int!) {
    loans_by_pk(id: $id) {
      id
      loan_number
      customer {
        id
        mobile_number
      }
      kyc {
        id
        legal_name
        id_proof
        address
        address_proof
        live_photo
        mothers_name
        fathers_name
        pan_number
        pan_proof
        date_of_birth
        marital_status
        gender
        form60_proof
      }
    }
  }
`;

const UserKYCDetails = (props) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const [liveImage, setLiveImage] = useState([]);

  const [idProof, setIdProof] = useState([]);
  const [addressProof, setAddressProof] = useState([]);
  const [panProof, setPanProof] = useState([]);
  const [form60Proof, setForm60Proof] = useState([]);

  const {
    loading: userKYCDetailsLoading,
    data: userKYCDetails,
    error: userKYCDetailsQueryError,
  } = useQuery(GET_USER_KYC_DETAILS, {
    variables: {
      id: props.loan_id,
    },
  });

  useEffect(() => {
    if (userKYCDetails !== undefined) {
      async function getUrl() {
        let id_proof = await Promise.all(
          (userKYCDetails?.loans_by_pk.kyc?.id_proof || []).map(async (proof) => {
            const valid_url = await get_image_url({
              url: [proof.image_url],
            });

            return {
              caption: `ID proof: ${proof.type}`,
              source: valid_url,
              document_number: proof.document_number,
            };
          })
        );

        let address_proof = await Promise.all(
          (userKYCDetails?.loans_by_pk.kyc?.address_proof || []).map(async (proof) => {
            const valid_url = await get_image_url({
              url: [proof.image_url],
            });
            return {
              caption: `Address proof: ${proof.type}`,
              source: valid_url,
              document_number: proof.document_number,
            };
          })
        );

        let pan_proof = [];
        if (userKYCDetails?.loans_by_pk?.kyc?.pan_proof) {
          pan_proof = await Promise.all(
            (userKYCDetails?.loans_by_pk.kyc.pan_proof || []).map(async (proof) => {
              const valid_url = await get_image_url({
                url: [proof.image_url],
              });
              return {
                caption: `PAN proof: ${proof.type}`,
                source: valid_url,
                document_number: proof.document_number,
              };
            })
          );
        }
        let form60_proof = [];
        if (userKYCDetails?.loans_by_pk?.kyc?.form60_proof) {
          form60_proof = await Promise.all(
            (userKYCDetails?.loans_by_pk?.kyc?.form60_proof || []).map(async (proof) => {
              const valid_url = await get_image_url({
                url: [proof.image_url],
              });
              return {
                caption: `Form 60 proof: ${proof.type}`,
                source: valid_url,
                document_number: proof.document_number,
              };
            })
          );
        }

        let valid_url = await get_image_url({
          url: [userKYCDetails?.loans_by_pk?.kyc?.live_photo || ''],
        });

        let live_image = {
          caption: 'User Image',
          source: valid_url,
        };

        setIdProof([...id_proof]);
        setAddressProof([...address_proof]);
        pan_proof && pan_proof.length > 0
          ? setPanProof([...pan_proof])
          : setForm60Proof([...form60_proof]);
        setLiveImage([live_image]);
      }

      getUrl();
    }
  }, [userKYCDetails]);

  if (userKYCDetailsLoading) return null;

  // if (userKYCDetailsQueryError) console.log(userKYCDetailsQueryError);

  return (
    <div>
      <div className='text-right space-x-4'>
        <button
          type='submit'
          className='text-xs btn-white'
          style={{ minWidth: '170px' }}
          onClick={async () => {
            // setImages([...id_proof, ...address_proof, ...pan_proof]);
            setIsImageModalOpen(true);
          }}
        >
          View KYC data
        </button>

        {/* Pdf is dynamically generated when the user clicks on the button */}
        <button
          className='text-xs cta px-4 py-2'
          onClick={async () => {
            // const props = await getProps();
            const doc = (
              <DownloadUserKYCDetails
                data={userKYCDetails?.loans_by_pk.kyc}
                id_proof={idProof}
                address_proof={addressProof}
                pan_proof={panProof}
                form60_proof={form60Proof}
                live_photo={liveImage}
              />
            );
            const asPdf = pdf([]); // {} is important, throws without an argument
            asPdf.updateContainer(doc);
            const blob = await asPdf.toBlob();
            saveAs(
              blob,
              `Customer KYC for loan ID: ${userKYCDetails?.loans_by_pk?.loan_number}.pdf`
            );
          }}
        >
          Export KYC details
        </button>

        {/* OLD CODE - PDF generation happens when page loads. This was scrapped since this led to too much load on the browser */}
        {/* {idProof.length > 0 &&
        addressProof.length > 0 &&
        panProof.length > 0 &&
        liveImage.length > 0 ? (
          <PDFDownloadLink
            document={
              <DownloadUserKYCDetails
                data={userKYCDetails?.loans_by_pk.kyc}
                id_proof={idProof}
                address_proof={addressProof}
                pan_proof={panProof}
                live_photo={liveImage}
              />
            }
            fileName={`Customer KYC for loan ID: ${userKYCDetails?.loans_by_pk.loan_number}.pdf`}
            style={{
              textDecoration: "none",
              color: "black",
              backgroundColor: "#f7c366",
              padding: "0.5rem 1rem 0.5rem 1rem",
              height: "fit-content",
              boxShadow: "0 0 3px rgba(0, 0, 0, 0.25)",
              borderRadius: "4px",
              // border: "1px solid #4a4a4a"
            }}
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Export KYC details"
            }
          </PDFDownloadLink>
        ) : null} */}
      </div>

      <div className='space-y-4'>
        <h4>USER INFO & KYC DETAILS</h4>
        <div className='flex border bg-white'>
          <div className='m-3 w-1/4 break-all border-r'>
            <p className='text-xs'>USER'S NAME</p>
            <p className='text-base font-bold'>
              {userKYCDetails?.loans_by_pk?.kyc?.legal_name}
            </p>
          </div>

          <div className='m-3 w-1/4 break-all border-r'>
            <p className='text-xs'>MOBILE NUMBER</p>
            <p className='text-base font-bold'>
              {userKYCDetails?.loans_by_pk.customer?.mobile_number}
            </p>
          </div>

          {/* <div className="m-3 w-2/4 break-all border-r">
            <p>ADDRESS</p>
            <p className="font-bold">
              {userKYCDetails?.loans_by_pk.kyc.address
                ? `${userKYCDetails?.loans_by_pk.kyc.address.addr_line_1}, ${userKYCDetails?.loans_by_pk.kyc.address.addr_line_2},
                    ${userKYCDetails?.loans_by_pk.kyc.address.city}, ${userKYCDetails?.loans_by_pk.kyc.address.state} - ${userKYCDetails?.loans_by_pk.kyc.address.pincode}`
                : null}
            </p>
          </div> */}

          {userKYCDetails?.loans_by_pk?.kyc?.date_of_birth ? (
            <div className='m-3 w-1/6 break-all'>
              <p className='text-xs'>DOB</p>
              <p className='text-base font-bold'>
                {moment(userKYCDetails?.loans_by_pk?.kyc.date_of_birth)?.format(
                  'DD.MM.YYYY'
                )}
              </p>
            </div>
          ) : null}

          <div className='m-3 w-1/4 break-all border-r'>
            <p className='text-xs'>USER PHOTO</p>
            <button
              className='text-base underline hover:underline text-yellow-primary'
              onClick={async () => {
                // setImages([...id_proof, ...address_proof, ...pan_proof]);
                setIsImageModalOpen(true);
              }}
            >
              View
            </button>
          </div>
        </div>
      </div>

      <ModalGateway>
        {isImageModalOpen ? (
          <Modal
            onClose={() => {
              setIsImageModalOpen(!isImageModalOpen);
            }}
          >
            <Carousel
              views={[
                ...liveImage,
                ...idProof,
                ...addressProof,
                ...(panProof && panProof.length > 0 ? panProof : form60Proof),
              ]}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default UserKYCDetails;
