import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import React from 'react';
import moment from 'moment';

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: "#E4E4E4"
  },
  image: {
    // height: 200,
    // width: 150
    marginTop: 10,
    marginBottom: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    // flexGrow: 1,
    // borderBottom: "1pt solid black"
    // backgroundColor: "#4f4f4f"
  },
  text: {
    fontSize: 14,
    lineHeight: 2,
    // borderBottom: "1px solid #000"
  },
});

const KYCDetails = (props) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View
          style={[
            styles.section,
            {
              textAlign: 'center',
              borderBottom: '1pt solid black',
            },
          ]}
        >
          <Text style={[styles.text, { fontSize: 18, fontWeight: 'bold' }]}>
            {props?.data?.legal_name}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.text}>
            Mother's name: {props?.data?.mothers_name}
          </Text>
          <Text style={styles.text}>
            Father's name: {props?.data?.fathers_name}
          </Text>
          <Text style={styles.text}>
            Date of birth:{' '}
            {moment(props?.data?.date_of_birth).format('DD.MM.YYYY')}
          </Text>
          <Text style={styles.text}>
            Gender:{' '}
            {props?.data?.gender === 'MALE'
              ? 'Male'
              : props?.data?.gender === 'FEMALE'
              ? 'Female'
              : props?.data?.gender === 'TRANSGENDER'
              ? 'Transgender'
              : 'N/A'}
          </Text>
          <Text style={styles.text}>
            Marital status:{' '}
            {props?.data?.marital_status === 'MARRIED'
              ? 'Married'
              : props?.data?.marital_status === 'SINGLE'
              ? 'Single'
              : 'N/A'}
          </Text>
        </View>

        <View style={styles.section} break>
          {props?.live_photo && props?.live_photo?.length ? props?.live_photo.map((proof) => {
            return (
              <View break>
                <Text style={styles.text}>{proof.caption}</Text>
                <Image source={proof.source} />
              </View>
            );
          }) : null}
        </View>

        <View style={styles.section} break>
          {/* <Text style={styles.text}>
            Address: {props.data.address.addr_line_1},{" "}
            {props.data.address.addr_line_2}, {props.data.address.city},{" "}
            {props.data.address.state} - {props.data.address.pincode}
          </Text> */}
          {/* <Text style={styles.text}>Address proof: </Text> */}
          { props?.address_proof && props?.address_proof?.length ? props?.address_proof?.map((proof) => {
            return (
              <View break>
                <Text style={styles.text}>
                  {proof.caption} - {proof.document_number}
                </Text>
                <Image source={proof.source} />
              </View>
            );
          }): null}
        </View>

        <View style={styles.section} break>
          {/* <Text style={styles.text}>ID proof: </Text> */}
          {props?.id_proof && props?.id_proof?.length ? props?.id_proof?.map((proof, index) => {
            return (
              <View break={index}>
                <Text style={styles.text}>
                  {proof.caption} - {proof.document_number}
                </Text>
                <Image source={proof.source} />
              </View>
            );
          }) : null}
        </View>

        <View style={styles.section} break>
          {/* <Text style={styles.text}>PAN proof: </Text> */}
          {props?.pan_proof && props?.pan_proof.length > 0
            ? props?.pan_proof?.map((proof, index) => {
                return (
                  <View break={index}>
                    <Text style={styles.text}>
                      {proof.caption} - {proof.document_number}
                    </Text>
                    <Image source={proof.source} />
                  </View>
                );
              })
            : props?.form60_proof && props?.form60_proof?.length ? props?.form60_proof?.map((proof, index) => {
                return (
                  <View break={index}>
                    <Text style={styles.text}>
                      {proof.caption} - {proof.document_number}
                    </Text>
                    <Image source={proof.source} />
                  </View>
                );
              }): null}
        </View>

        {/* <View style={styles.section} break>
          <Text style={styles.text}>PAN number: {props.data.pan_number}</Text>
          <Text style={styles.text}>PAN proof: </Text>
          {props.pan_proof.map(proof => {
            return (
              <View break>
                <Text style={styles.text}>{proof.caption}</Text>
                <Image source={proof.source} />
              </View>
            );
          })}
        </View> */}

        {/* <View style={styles.section} break>
          <Text style={styles.text}>Recent photo: </Text>
          <Image source={props.live_photo} />
        </View> */}
      </Page>
    </Document>
  );
};

export default KYCDetails;
