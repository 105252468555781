import React, { useEffect, useState } from "react";
import { Number, Currency } from "../components/Number";
// import axios from "axios";
// import AuthUtils from "../utils/AuthUtils";
import ApiUtils from "../utils/ApiUtils";
import AuthUtils from "../utils/AuthUtils";

const LoanDetails = (props) => {
  const [closureAndReasonData, setClosureAndReasonData] = useState(null);
  useEffect(() => {
    ApiUtils.get(`/api/v1/ops/loan/${props.loan_number}`, {
      baseURL: process.env.REACT_APP_TAKEOVER,
      headers: {
        Authorization: `JWT ${AuthUtils.getToken()}`,
      },
    })
      .then((response) => {
        setClosureAndReasonData(response?.data);
      })
      .catch(console.log);
  }, [props.loan_number]);
  return (
    <div className="space-y-2 w-1/2">
      <h4>{props.title ?? "LOAN DETAILS"}</h4>

      <div className="border bg-white">
        {/* ROW 1 */}
        <div className="border-b flex p-4 w-full">
          <div className="w-1/3">
            <h5 className="text-xs">LOAN ID</h5>
            <p className="text-base font-bold text-black">
              {props.loan_number}
            </p>
          </div>
          <div className="w-1/3">
            <h5 className="text-xs">LOAN TYPE</h5>
            <p className="text-base font-bold text-black">
              {props.loan_type === "OD" ? "Overdraft" : "Gold loan"}
            </p>
          </div>
          <div className="w-1/3">
            <h5 className="text-xs">LOAN AMOUNT / CREDIT LIMIT</h5>
            <p className="text-base font-bold text-black">
              ₹ <Currency number={props.loan_amount} />
            </p>
          </div>
        </div>
        {/* ROW 1 */}

        {/* ROW 2 */}
        <div className="border flex bg-white">
          <div className="flex p-4 w-full">
            <div className="w-1/3">
              <h5 className="text-xs">GOLD GROSS WT.</h5>
              <p className="text-base font-bold text-black">
                <Number number={props.gold_gross_weight} /> grams
              </p>
            </div>
            <div className="w-1/3">
              <h5 className="text-xs">ACUTAL GOLD NET WT.</h5>
              <p className="text-base font-bold text-black">
                <Number number={props.gold_actual_net_weight} /> grams
              </p>
            </div>
            <div className="w-1/3">
              <h5 className="text-xs">EQ. GOLD NET WT. (22 carats)</h5>
              <p className="text-base font-bold text-black">
                <Number number={props.gold_net_weight} /> grams
              </p>
            </div>
          </div>
        </div>
        {/* ROW 3 */}
        {closureAndReasonData?.ekycAdditionalDetails?.loanPurpose ||
        closureAndReasonData?.closureRequest?.[0]?.userReasonDescription ? (
          <div className="border flex bg-white">
            <div className="flex p-4 w-full">
              {closureAndReasonData?.ekycAdditionalDetails?.loanPurpose ? (
                <div className="w-1/3">
                  <h5 className="text-xs">LOAN PURPOSE</h5>
                  <p className="text-base font-bold text-black">
                    {closureAndReasonData?.ekycAdditionalDetails?.loanPurpose}
                  </p>
                </div>
              ) : null}

              {closureAndReasonData?.closureRequest?.[0]
                ?.userReasonDescription ? (
                <div className="w-1/2">
                  <h5 className="text-xs uppercase">Reason for Closure</h5>
                  <p className="text-base font-bold text-black">
                    {
                      closureAndReasonData?.closureRequest?.[0]
                        ?.userReasonDescription
                    }
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LoanDetails;
